import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-ors-modellfly-main',
  templateUrl: './ors-modellfly-main.component.html',
  styleUrls: ['./ors-modellfly-main.component.css']
})
export class NlfOrsModellflyMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
