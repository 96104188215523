import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-resolve',
  templateUrl: './resolve.component.html',
  styleUrls: ['./resolve.component.css']
})
export class NlfResolveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
