import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-ors-stats',
  templateUrl: './ors-stats.component.html',
  styleUrls: ['./ors-stats.component.css']
})
export class NlfOrsStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
