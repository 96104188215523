import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-resolve-lungo-county',
  templateUrl: './resolve-lungo-county.component.html',
  styleUrls: ['./resolve-lungo-county.component.css']
})
export class ResolveLungoCountyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
