import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nlf-confluence',
  templateUrl: './confluence.component.html',
  styleUrls: ['./confluence.component.css']
})
export class NlfConfluenceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
